import {
	Box,
	Chip,
	CircularProgress,
	FormControl,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select
} from "@mui/material"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQueries } from "react-query"
import { useSearchParams } from "react-router-dom"

const ShaxmatkaTopPanel = () => {
	const { t } = useTranslation()
	const [hasError, setHasError] = useState(false)
	const axiosPrivate = useAxiosPrivate()
	const [selectedObjectId, setSelectedObjectId] = useState("")
	const [selectedBlocksId, setSelectedBlocksId] = useState([])
	const [searchParams, setSearchParams] = useSearchParams()

	const [objects, blocks] = useQueries([
		{
			queryKey: "objectsSelect",
			queryFn: async function () {
				const response = await axiosPrivate.get("/dictionary/objects2")
				return response.data.data
			},
			enabled: !hasError,
			onSuccess: (data) => {
				if (data && data.length > 0) {
					let entries = Object.fromEntries(searchParams)
					if (Object.keys(entries).includes("objectId")) {
						setSelectedObjectId(searchParams.get("objectId"))
					} else {
						setSelectedObjectId(data[0]?.id)
						searchParams.set("objectId", data[0]?.id)
						setSearchParams(searchParams)
					}
				}
			},
			onError: (error) => {
				setHasError(true)
			},
			retry: false
		},
		{
			queryKey: "blocksSelect",
			queryFn: async function () {
				const response = await axiosPrivate.get(
					`/admin/block/index/${selectedObjectId}`
				)
				return response.data.data
			},
			onSuccess: (data) => {
				let entries = Object.fromEntries(searchParams)
				let blocksFromPath = []
				Object.keys(entries).forEach((item) => {
					if (item.includes("block")) {
						blocksFromPath.push(parseInt(entries[item]))
					}
				})
				setSelectedBlocksId(blocksFromPath)
			},
			enabled: false,
			onError: (error) => {
				setHasError(true)
			},
			retry: false
		}
	])

	const handleChangeObject = (event) => {
		const {
			target: { value }
		} = event
		searchParams.set("objectId", value)
		clearAll()
		setSelectedBlocksId([])
		setSelectedObjectId(value)
		setSearchParams(searchParams)
	}

	const handleChangeBlocks = (event) => {
		const {
			target: { value }
		} = event
		searchParams.set(
			`block[${selectedBlocksId.length}]`,
			value[value.length - 1]
		)
		setSearchParams(searchParams)
		setSelectedBlocksId(value)
	}

	const handleDeleteBlockItem = (value) => {
		let leftSelectedBlocks = selectedBlocksId.filter((item) => item !== value)
		clearAll()
		leftSelectedBlocks.forEach((item, index) =>
			searchParams.set(`block[${index}]`, item)
		)
		setSearchParams(searchParams)
		setSelectedBlocksId(leftSelectedBlocks)
	}

	const clearAll = () => {
		let entries = Object.fromEntries(searchParams)
		Object.keys(entries).forEach((item) => {
			if (item.includes("block")) searchParams.delete(item)
		})
	}

	const getChipNameById = (id) => {
		let foundBlock =
			blocks.data &&
			blocks.data.length > 0 &&
			blocks.data.find((item) => item.id === id)
		if (foundBlock) {
			return foundBlock.name
		}
		return ""
	}

	const handleClearSelectBox = () => {
		clearAll()
		setSelectedBlocksId([])
		setSearchParams(searchParams)
	}

	useEffect(() => {
		if (selectedObjectId) {
			blocks.refetch()
		}
	}, [selectedObjectId])

	return (
		<div className="flex flex-row items-center w-full">
			<div className="text-lg">{t("shaxmatka.shortTitle")} |</div>
			<div className="w-[500px] ml-2">
				<Grid container spacing={1} columns={16}>
					<Grid item={true} sm={6} xs={16}>
						<FormControl fullWidth color="formColor">
							<InputLabel id="objectId-label">
								{t("common.fields.objectName")}
							</InputLabel>
							<Select
								labelId="objectId-label"
								id="objectId-select"
								label={t("common.fields.objectName")}
								onChange={handleChangeObject}
								value={selectedObjectId}
								color="formColor"
								variant="outlined"
								role="presentation"
								MenuProps={{ id: "objectId-select-menu" }}
								size="small"
							>
								{objects.isLoading || objects.isFetching ? (
									<div className="circular-progress-box">
										<CircularProgress size={25} />
									</div>
								) : objects.data && objects.data.length > 0 ? (
									objects.data.map((item, index) => (
										<MenuItem
											value={item.id}
											key={index + 1}
											className="small-size"
										>
											{item.name}
										</MenuItem>
									))
								) : (
									<div>
										<span className="no-data-found-wrapper select-box">
											<i className="bi bi-exclamation-octagon text-lg mr-1" />{" "}
											{t("common.global.noDataFound")}
										</span>
									</div>
								)}
							</Select>
						</FormControl>
					</Grid>
					<Grid item={true} sm={10} xs={16}>
						<FormControl fullWidth color="formColor">
							<InputLabel id="blocks-filter-label">
								{t("common.filter.blocks")}
							</InputLabel>
							<Select
								labelId="blocks-filter-label"
								id="blocks-filter-multiple-chip"
								multiple
								size="small"
								value={selectedBlocksId}
								onChange={handleChangeBlocks}
								input={
									<OutlinedInput
										id="blocks-filter-select-multiple-chip"
										variant="filterField"
										label={t("common.filter.blocks")}
									/>
								}
								renderValue={(selected) => (
									<Box
										sx={{
											display: "flex",
											flexWrap: "nowrap",
											gap: 0.5,
											overflow: "hidden"
										}}
									>
										{selected.map((value) => (
											<Chip
												key={`block-${value}`}
												label={getChipNameById(value)}
												variant="tableBadge"
												color="primary"
												onMouseDown={(event) => {
													event.stopPropagation()
												}}
												size="small"
												onDelete={() => handleDeleteBlockItem(value)}
											/>
										))}
									</Box>
								)}
								color="formColor"
								variant="outlined"
								MenuProps={{
									id: "blocks-filter-select-menu",
									PaperProps: {
										style: {
											maxHeight: 300
										}
									},
									disableScrollLock: true
								}}
								endAdornment={
									<InputAdornment
										position="end"
										className="custom-endAdornment"
									>
										<IconButton
											onClick={() => handleClearSelectBox()}
											size="small"
											variant="onlyIcon"
											sx={{
												display: selectedBlocksId.length > 0 ? "" : "none"
											}}
											className="select-box-end-adornment-button"
										>
											<i className="bi bi-x end-adornment-close-button" />
										</IconButton>
									</InputAdornment>
								}
							>
								{blocks.isLoading || blocks.isFetching ? (
									<div className="circular-progress-box">
										<CircularProgress size={25} />
									</div>
								) : blocks.data && blocks.data.length > 0 ? (
									blocks.data.map((item, index) => (
										<MenuItem
											value={item.id}
											key={`block-${index + 1}`}
											className="small-size"
										>
											{item.name}
										</MenuItem>
									))
								) : (
									<div>
										<span className="no-data-found-wrapper select-box">
											<i className="bi bi-exclamation-octagon text-lg mr-1" />{" "}
											{t("common.global.noDataFound")}
										</span>
									</div>
								)}
							</Select>
						</FormControl>
					</Grid>
				</Grid>
			</div>
		</div>
	)
}
export default ShaxmatkaTopPanel
