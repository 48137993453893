const CLIENT_TYPE = {
	PHYSICAL: {
		code: "1",
		label: "common.typeList.client.physical"
	},
	LEGAL: {
		code: "2",
		label: "common.typeList.client.legal"
	}
}

export default CLIENT_TYPE
