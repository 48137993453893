import useTopPanel from "hooks/useTopPanel"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"

const CRM = () => {
	const { t, i18n } = useTranslation()
	const { setComponent } = useTopPanel()

	useEffect(() => {
		setComponent(<div className="component-title">{t("boss.crm.title")}</div>)
	}, [i18n.language])

	return <div>CRM</div>
}
export default CRM
